import React from 'react';

function Block(props) {
    const bgColor = props.bgcolor || 'transparent';
    const bgImg = props.bgimg ? `url(${props.bgimg})` : 'none';
    const color = props.color || 'black';
    const padding = props.padding || '4rem 0';
    return (
        <div style={{
            backgroundImage: bgImg,
            backgroundColor: bgColor,
            color: color,
            padding: padding,
            ...props.style
        }} className={props.className} id={props.id}>
            {props.children}
        </div>
    );
}

export default Block;
import React from 'react';
import Card from 'react-bootstrap/Card';
import { Container } from 'react-bootstrap';
import Block from './Block';

function Sobre() {
    return (
        <Block className="sobre">
            <Container>
                <div className='cardBox'>
                    <Card>
                        <Card.Body>
                            <Card.Title>Responsabilidades</Card.Title>
                            <Card.Text>
                                <p>Faço parte da coordenação do Projeto Epoché, projeto iniciado por um grupo de psicólogos de Maringá (PR), que tem o objetivo de facilitar o acesso de estudantes a psicoterapia.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    
                    <Card>
                        <Card.Body>
                            <Card.Title>Curiosidades</Card.Title>
                            <Card.Text>
                                <p>Antes de me dedicar à psicologia, me formei em comunicação social, com ênfase em jornalismo, e atuei durante quatro anos na área da assessoria de imprensa.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    
                    <Card>
                        <Card.Body>
                            <Card.Title>Trajetória</Card.Title>
                            <Card.Text>
                                <p>Durante minha trajetória na psicologia, trabalhei em consultório particular e como psicóloga voluntária na APMIF São Rafael e no Projeto Domus Pueri, ambos em Maringá (PR). Fui membro do Instituto Psicologia em Foco, auxiliando na redação e divulgação do Jornal Psicologia em Foco. Tenho experiência em psicologia escolar, quando atuei em escolas de Curitiba (PR), Maringá (PR) e Presidente Castelo Branco (PR).  Além de realizar estágio supervisionado em saúde mental nas instituições: CAPS II, emergência psiquiátrica e UBs.</p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Block>
    )
}

export default Sobre;
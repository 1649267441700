import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Pages } from '../App';
import { Button, Image } from 'react-bootstrap';
import logo from '../assets/logo_tk_250x250.png';

function Menu(props) {
  const color = props.color || 'black';
  return (
    <Navbar expand="md" style={{
      backgroundColor: props.bgcolor || '',
      color: color
    }}>
      <Container>
        <Navbar.Brand href="/" style={{color: color }}>
          Psicóloga Thaís Koga
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
          <Nav>
            {Pages.map((page, index) => (
              <Nav.Link key={index} href={page.url}  style={{color: color }}>{page.label}</Nav.Link>
            ))}
            <Button variant='success' href='https://w.app/mpZkN6?widget'>
              Agende seu atendimento
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Menu;
import React from 'react';
import Image from 'react-bootstrap/Image';
import Typewriter from './Typewriter';
import Block from './Block';
import { Container } from 'react-bootstrap';


function Home(props) {
    return (
        <Block id='home'>
            <Container className='home'>
                <div className='mainPicture'>
                    <Image src="/img/tk.jpg" rounded />
                </div>
                <div className='presentation' style={{
                    backgroundColor: props.bgcolor || 'transparent',
                    color: props.color || 'black',
                }}>
                    <h1 className='typewriter '>
                        <Typewriter text="Olá, sou a Thaís." delay={100} />
                    </h1>
                    <p>
                        Sou psicóloga graduada pela UniCesumar, com formação em Gestalt-terapia pelo Instituto Gestalt Paraná
                        e pós-graduada em Avaliação psicológica pela UniCesumar.
                        Atuo na área da psicologia clínica, com atendimentos individuais de adultos e idosos
                        nas modalidades presencial e online por meio da abordagem Gestalt-terapia.
                    </p>
                </div>
            </Container>
        </Block>
    )
}

export default Home;
import React from 'react';

function Footer(props) {
    return (
        <footer className="footer" style={{
            borderTop: '1px solid #ccc',
            backgroundColor: props.bgcolor || 'white',
            color: props.color || 'black',
        }}>
            <p className='m-0'>&copy; {new Date().getFullYear()} Thaís Fernandes Koga</p>
        </footer>
    )
}

export default Footer;
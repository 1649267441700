import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import Block from './Block';

function Contato(props) {
    return (
        <Block id='' style={{
            color: props.color || 'black',
        }} className='contato'>
            <div className='contato-box' style={{
                backgroundColor: props.bgcolor || 'transparent',
            }}>
                <div className='split'>
                    <div>
                        <Image src="/img/qrcode.png" fluid style={{
                            maxWidth: '200px',
                            border: '1px solid #ccc',
                        }} />
                    </div>
                    <div>
                        <p>
                            +55 41 99143 3013
                        </p>
                        <p>
                            thaisf.koga@gmail.com
                        </p>
                    </div>
                </div>
            </div>
        </Block >
    )
}

export default Contato;
import './assets/App.css';
import Contato from './components/Contato';
import Home from './components/Home';
import Menu from './components/Menu';
import Sobre from './components/Sobre';
import Footer from './components/Footer';
import Block from './components/Block';
import BackgroundImg from './assets/StripeBG.png';


export const Pages = [
  {
    url: '#home',
    label: 'Home',
    component: Home,
  },
  {
    url: '#sobre',
    label: 'Sobre',
    component: Sobre,
  },
  {
    url: '#contato',
    label: 'Contato',
    component: Contato,
  },
];

{/* https://flatuicolors.com/palette/ru */ }

function App() {
  return (
    <div className='main' style={{
      backgroundImage: `url(${BackgroundImg})`,
    }}>
      <Menu />
      <Home />
      <Block id="sobre" className='separador' >
        <h3> Sobre</h3>
      </Block>
      <Sobre />
      <Block id="contato" className='separador' >
        <h3> Contato</h3>
      </Block>
      <Contato />
      <Footer />
    </div>
  );
}


export default App;
